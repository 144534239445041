module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/node_modules/@gregorshear/gatsby-theme-taproot/src/intl","languages":["en-US"],"defaultLanguage":"en-US"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/node_modules/@gregorshear/gatsby-theme-taproot/src/layouts/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"thebeaconschool.org","short_name":"Beacon","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"browser","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9cfc65eabcd4e3eb7acb0f3a95eb2cd"},
    },{
      plugin: require('../node_modules/@gregorshear/gatsby-theme-taproot/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"contentful":{"spaceId":"tfkdka8hjgc4","accessToken":"g5WYQkp7kkNYm3lZinhkLcO0PXlC8KNAj0TpJTzJPbs"},"manifest":{"name":"thebeaconschool.org","short_name":"Beacon"},"tailwindTheme":{"extend":{"colors":{"primary":{"50":"#f7fee7","100":"#ecfccb","200":"#d9f99d","300":"#bef264","400":"#a3e635","500":"#84cc16","600":"#65a30d","700":"#4d7c0f","800":"#3f6212","900":"#365314"},"secondary":{"50":"#fff7ed","100":"#ffedd5","200":"#fed7aa","300":"#fdba74","400":"#fb923c","500":"#f97316","600":"#ea580c","700":"#c2410c","800":"#9a3412","900":"#7c2d12"}}}}},
    }]
